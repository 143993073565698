import React from "../hooks/reactImport"
import Payment from "../components/Builder/Components/Stripe/Payment"
import { Head } from "../components/Builder/Head"
import NotificationManager from "../components/Builder/Components/Notification/NotificationManager"
import { useEffect, useRef } from "../hooks/stateHooks"
import { setNotificationRef } from "../components/Builder/Components/Notification/Notify"

export default function CheckoutForm(props) {
  const notificationRef = useRef()
  const length = props?.location?.search?.split("=").length
  const gatewayId = props?.location?.search
    ?.split("=")
    [length - 2]?.split("&")[0]
  const redirectUrl = props?.location?.search?.split("=")[length - 1]

  useEffect(() => {
    setNotificationRef(notificationRef)
  }, [notificationRef])
  return (
    <>
      <Head />
      <NotificationManager ref={notificationRef} />
      <Payment gatewayId={gatewayId} redirectUrl={redirectUrl} />
    </>
  )
}
